<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <b-img fluid :src="logoImg" alt="Logo da Agenda"></b-img>
        </h2>
      </b-link>

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-2">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Esqueceu a Senha" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Esqueceu sua senha? 🔒 </b-card-title>
          <b-card-text class="mb-2"> Insira seus dados a abaixo para resetar sua senha. </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input
                    id="forgot-password-email"
                    v-model="dados.email"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Data Nascimento" label-for="dataNascimento">
                <validation-provider #default="{ errors }" name="Data Nascimento" rules="required">
                  <cleave
                    id="dataNascimento"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    v-model="dados.dataNascimento"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="telefone" label="Telefone">
                <cleave
                  id="telefone"
                  v-model="dados.telefone"
                  class="form-control"
                  :raw="false"
                  :options="options.clevePhone"
                  placeholder="Telefone"
                  rules="required"
                />
              </b-form-group>

              <b-button type="submit" variant="primary" block> Enviar </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }"> <feather-icon icon="ChevronLeftIcon" /> Volte a tela de login </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal
      id="modal-auth-resetaSenha"
      ref="modal-auth-resetaSenha"
      centered
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
      hide-backdrop
      hide-header
      hide-footer
    >
      <div class="auth-inner px-2 py-2">
        <!-- Reset Password v1 -->
        <b-card class="mb-0">
          <h3 title-tag="h4" class="text-primary text-center mb-2">Redefina sua senha</h3>
          <b-card-text class="mb-3"> Sua nova senha precisa ser diferente das anteriores </b-card-text>

          <validation-observer ref="formSenha">
            <b-form class="auth-reset-password-form mt-2">
              <!-- password -->
              <b-form-group label="Nova Senha" label-for="reset-password-new">
                <validation-provider #default="{ errors }" name="Nova Senha" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="reset-password-new"
                      v-model="senhaNova"
                      :type="campoSenhaNovo"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="Digite sua nova senha"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="campoSenhaNovo" @click="togglePassword1Visibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group label-for="reset-password-confirm" label="Confirme a nova senha">
                <validation-provider
                  #default="{ errors }"
                  name="Confirme a nova senha"
                  :rules="{
                    required: true,
                    camposIguais: senhaNova,
                  }"
                >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="confirmaSenhaNova"
                      :type="cCampoSenhaNovo"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="Confirme sua atual senha"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="cCampoSenhaNovo" @click="togglePassword2Visibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <p class="text-center">
                <!-- submit button -->
                <b-button type="submit" variant="primary" class="mt-1 mr-1" @click="salvar"> Salvar nova senha </b-button>
              </p>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }"> <feather-icon icon="ChevronLeftIcon" /> Volte ao login </b-link>
          </p>
        </b-card>
        <!-- /Reset Password v1 -->
      </div>
    </b-modal>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email, camposIguais } from '@validations'
  import store from '@/store/index'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { formatarDataGlobal } from '@/libs/utils'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      Cleave,
    },
    data() {
      return {
        dados: {},
        user: null,
        sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
        // validation
        required,
        email,
        camposIguais,
        logoImg: require('@/assets/images/pages/logoAgenda.png'),
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          clevePhone: { phone: true, phoneRegionCode: 'BR', delimiter: ' ' },
        },
        senhaNova: '',
        confirmaSenhaNova: '',
        campoSenhaNovo: 'password',
        cCampoSenhaNovo: 'password',
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
      logoAgenda() {
        this.logoImg = require('@/assets/images/pages/logoAgenda.png')
        return this.logoImg
      },
    },
    methods: {
      togglePassword1Visibility() {
        this.campoSenhaNovo = this.campoSenhaNovo === 'password' ? 'text' : 'password'
      },
      togglePassword2Visibility() {
        this.cCampoSenhaNovo = this.cCampoSenhaNovo === 'password' ? 'text' : 'password'
      },
      validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.loading = true; 
            useJwt
              .forgotPassword({
                email: this.dados.email,
                dataNascimento: formatarDataGlobal(this.dados.dataNascimento),
                telefone: this.dados.telefone,
              })
              .then((response) => {
                if (response.data && response.data.status === 'erro') {
                  this.$message.error(response.data.mensagem || 'Usuário não encontrado.'); 
                } else if (response.data) {
                  
                  this.user = response.data; 
                  this.$message.info('O cadastro foi encontrado!');
                  this.$refs['modal-auth-resetaSenha'].show(); 
                }

                this.loading = false; 
              })
              .catch((error) => {
                this.loading = false; 
                this.$message.error('Erro ao buscar dados de usuário');
                console.error(error); 
              });
          }
        });
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formSenha.validate().then((success) => {
          if (success) {
            useJwt
              .patch('/auth/alterarSenha', {
                email: this.dados.email,
                password: this.senhaNova,
              })
              .then((res) => {
                this.$message.success('Nova senha salva com sucesso!')
                this.$router.push('/login')
              })
              .catch((error) => {
                this.$message.error('Falha ao alterar senha.')
                console.error(error)
              })
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
